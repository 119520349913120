import React, { useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { H1, H2, Paragraph, ParagraphBold, Heading } from 'components/common/Styles';
import Seo from 'components/seo';
import { ContentArticle820 } from 'components/Content';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';
import Footer from 'components/Footer/Footer';

const EditorialPolicy = () => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};

	const appendText = (num = 1) => {
		let suffix = 'th';
		if (num == 0) suffix = '';
		if (num % 10 == 1 && num % 100 != 11) suffix = 'st';
		if (num % 10 == 2 && num % 100 != 12) suffix = 'nd';
		if (num % 10 == 3 && num % 100 != 13) suffix = 'rd';

		return String(num) + suffix;
	};

	const date = new Date();
	const oneWeekBeforeToday = date.setDate(date.getDate() - 7);
	const year = new Date(oneWeekBeforeToday).getFullYear();
	const dateNum = new Date(oneWeekBeforeToday).getDate();
	const month = new Date(oneWeekBeforeToday).toLocaleString('en-us', { month: 'long' });

	return (
		<>
			<Seo
				title={`About Us: Our Mission, Vision & Why`}
				description='Find out why we created BookPhysio.com, an on-demand physiotherapy booking site that connects patients with experienced physiotherapists.'
			/>
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<ContentArticle820 isOpen={isMenuOpen}>
						<section>
							<TopPadding />
							<Paragraph></Paragraph>
							<H1>About our Content</H1>
							<Paragraph>
								Finding pain, injury and condition information is easy - it's everywhere. But finding
								trustworthy, relevant, actionable help can be tricky and sometimes overwhelming.
								BookPhysio.com is changing all of that. With the help of our panel of expert
								contributors, medical reviewers and talented editorial team - we're building a
								comprehensive library of content to help everyday Australians get back to feeling their
								best - no matter what they're dealing with.
							</Paragraph>
							<ParagraphBold>
								We are dedicated to ensuring that our content is accessible, understandable and
								actionable so that all our readers can be confident in making well-informed decisions
								about their health.
							</ParagraphBold>
							<Paragraph>
								The medical accuracy and integrity of our content is paramount to us, so all of the
								articles featured within our
								<HoveredInline>
									<Link to='/patient-resources'>patient resources centre</Link>
								</HoveredInline>
								are vetted through our rigorous
								<SpanBold>content development process</SpanBold>- this means they are fact-checked and
								reviewed by medical experts before publishing.
							</Paragraph>
							<Paragraph>
								Our strict
								<SpanBold>sources and attribution guidelines</SpanBold>
								also ensure that we cite and link to the primary literature in each article we publish.
								Our team constantly researches and monitors the health space to update and review
								current articles and produce new content for our readership.
							</Paragraph>
							<Paragraph>
								"Read more about the team behind the content and the editorial process we have in place
								to ensure we have your back, and whatever else hurts too."
							</Paragraph>
							<Paragraph>
								<Line />
								<ImgsBlock>
									<FoundersImage>
										<StaticImage src='../images/Matt.jpeg' alt='Matt' width={100} height={100} />
									</FoundersImage>
									<FoundersImage>
										<StaticImage
											src='../images/team/scott.jpg'
											alt='Scott'
											width={100}
											height={100}
										/>
									</FoundersImage>
								</ImgsBlock>
								<SpanBold>(Matt + Scott) founders of BookPhysio.com</SpanBold>
							</Paragraph>
						</section>
						<section>
							<H2>Our Editorial Leadership Team</H2>
							<Paragraph>
								Our editorial team is responsible for ensuring the content on BookPhysio.com meets the
								standards we set in our overall editorial policy.
							</Paragraph>
							<Paragraph>
								The team also provides the strategic direction and planning of our content creation and
								works closely with our panel of expert contributors and medical reviewers to ensure the
								content on BookPhysio.com is up to date and accurate.
							</Paragraph>
							<Paragraph>
								“For those of us working at BookPhysio.com, the topic of healthcare is something we’re
								all passionate about - which is what motivates us to build upon our library of health
								information, to help you get back to feeling your best - no matter what you're dealing
								with.”<Span>- Leon Mao, Senior Content Producer</Span>
							</Paragraph>
						</section>
						<section>
							<ImgBlock>
								<StaticImage
									src='../images/editorial-team/leon.jpg'
									alt='Dr Leon Mao'
									width={200}
									height={200}
								/>
							</ImgBlock>
							<Paragraph>
								<Span>
									<Link to='/author/leon-mao'>Leon Mao, Senior Content Producer</Link>
								</Span>
								<SpanGrey>
									Bachelor of Science with Honours, Doctorate of Physiotherapy (University of
									Melbourne)
								</SpanGrey>
							</Paragraph>
							<Paragraph>
								Leon Mao is responsible for producing and editing content on BookPhysio.com and guiding
								the strategic direction of the content produced.
							</Paragraph>
							<Paragraph>
								In his role in the leadership team, Leon is also responsible for ensuring our content
								meets the standards we set in our overall editorial policy. Leon works closely with our
								panel of expert contributors and medical reviewers to ensure that what we publish is up
								to date and accurate.
							</Paragraph>
						</section>
						<section>
							<ImgBlock>
								<StaticImage
									src='../images/editorial-team/gina-arena.jpg'
									alt='Dr Gina Arena'
									width={200}
									height={200}
								/>
							</ImgBlock>
							<Paragraph>
								<Span>
									<Link to='/medical-reviewer/gina-arena'>
										Dr Gina Arena — Senior Medical Reviewer
									</Link>
								</Span>
								<SpanGrey>
									{`Research Fellow & Lecturer at the University of Western Australia`}
								</SpanGrey>
							</Paragraph>
							<Paragraph>
								Dr Gina Arena is the senior medical reviewer for content published within
								BookPhysio.com's patient resources. Dr Arena reviews all articles to ensure medical
								accuracy, with appropriate primary literature citations to bolster the evidence behind
								the content.
							</Paragraph>
						</section>
						<section>
							<H2>Editorial Contributors and Experts</H2>
							<Paragraph>
								We aim to construct content that is balanced and objective. To this end, we maintain a
								healthy relationship between many different Australian-based health practitioners - who
								often contribute and provide perspective from their area of medical expertise.
							</Paragraph>
							<Paragraph>
								We also work with accredited health writers and production companies to help produce
								some of our content published on our site.
							</Paragraph>
							<Paragraph>
								Whenever we include a contributor's perspective or advice within one of our articles, we
								clearly declare it within the footnotes.
							</Paragraph>
							<Hovered>
								<Link to='/editorial-team'>Meet some of our contributors</Link>
							</Hovered>
						</section>
						<section>
							<H2>Content Development Process</H2>
							<Paragraph>
								At BookPhysio.com, we have a clear pathway for content creation to ensure we maintain
								accuracy, consistency and relevance. We employ this process for each piece of content
								created and maintained on the BookPhysio.com website.
							</Paragraph>
							<Paragraph>
								BookPhysio.com's content development process includes the following 6 phases:
							</Paragraph>
							<ListItems>
								<Paragraph>
									<Item>
										<Num>1.</Num>
										<TextBlock>
											<SpanBoldInline>{`Plan & Research`}</SpanBoldInline>— planning sessions take
											place regularly to inform our content briefs.
										</TextBlock>
									</Item>
								</Paragraph>
								<Paragraph>
									<Item>
										<Num>2.</Num>
										<TextBlock>
											<SpanBoldInline>Write</SpanBoldInline>— the topic at hand is then written by
											one of our authors within our editorial team.
										</TextBlock>
									</Item>
								</Paragraph>
								<Paragraph>
									<Item>
										<Num>3.</Num>
										<TextBlock>
											<SpanBoldInline>Editorial Review</SpanBoldInline>— the article is then
											editorially reviewed by our accredited editors.
										</TextBlock>
									</Item>
								</Paragraph>
								<Paragraph>
									<Item>
										<Num>4.</Num>
										<TextBlock>
											<SpanBoldInline>{`Medically Review & Fact Check`}</SpanBoldInline>— once
											editorially reviewed, the article is then medically reviewed and/or
											fact-checked by one of our (Australian qualified) medical reviewers.
										</TextBlock>
									</Item>
								</Paragraph>
								<Paragraph>
									<Item>
										<Num>5.</Num>
										<TextBlock>
											<SpanBoldInline>Publish</SpanBoldInline>— once the review process is
											complete, the article is then published on our website.
										</TextBlock>
									</Item>
								</Paragraph>
								<Paragraph>
									<Item>
										<Num>6.</Num>
										<TextBlock>
											<SpanBoldInline>{`Review & Update`}</SpanBoldInline>— our articles are all
											regularly reviewed by our team of medical reviewers and expert contributors.
										</TextBlock>
									</Item>
								</Paragraph>
							</ListItems>
						</section>
						<section>
							<H2>Sources and Attribution Guidelines</H2>
							<Paragraph>
								All of our information is evidence-based, with content supported by reliable sources. We
								aim to use Australian, non-commercial sources, such as:
							</Paragraph>
							<ListItems>
								<Paragraph>
									<DotItem>Australian Government department information</DotItem>
									<DotItem>Australian data and statistics</DotItem>
									<DotItem>Clinical practice guidelines</DotItem>
									<DotItem>Peer-reviewed literature</DotItem>
								</Paragraph>
							</ListItems>
							<Paragraph>
								All references are listed at the end of our article, linking to the source where
								possible.
							</Paragraph>
						</section>
						<section>
							<H2>Content Maintenance and Quality Assurance</H2>
							<Paragraph>
								All of the BookPhysio.com content is reviewed regularly to ensure it is complete,
								accurate and trustworthy. We review all content within two years of first publishing,
								with some content reviewed more frequently due to the nature of the article. All
								articles are labelled with the "Last reviewed" date clearly at the top of the page to
								ensure complete transparency in our process.
							</Paragraph>
						</section>
						<section>
							<H2>Editorial Approach</H2>
							<Paragraph>
								Our approaches have been assembled to ensure BookPhysio.com's digital services and
								assets follow the best editorial practice and use consistent, correct, diverse and
								conscious language.
							</Paragraph>
							<Heading>Diversity Commitment</Heading>
							<Paragraph>
								Your Voice Matters Here, and we're prioritising an environment where all feel safe and
								supported.
							</Paragraph>
							<Paragraph>
								We are dedicated to engaging and amplifying various voices, identities, and viewpoints
								across the health and wellness spectrum to provide a content culture that reflects the
								diversity we see in our evolving world.
							</Paragraph>
							<Paragraph>
								We are committed to building and nurturing inclusive teams at every level, and we seek
								out individuals who bring unique perspectives and experiences and an eagerness to
								embrace diversity, equity, and inclusion.
							</Paragraph>
							<Paragraph>
								We use inclusive language, take responsibility for and learn from mistakes, and
								prioritise creating a work environment where all feel safe and supported.
							</Paragraph>
							<Paragraph>
								<SpanItalic>- Leon Mao, Senior Content Producer</SpanItalic>
							</Paragraph>
							<Heading>Conscious Language</Heading>
							<Paragraph>
								Language is a powerful instrument that constructs the world around us and can create and
								remove identities. At BookPhysio.com, we know that language matters, and we are invested
								in using language to create a healthier, stronger, and more impartial world. Employing
								conscious language in speaking, writing, and editing reflects our awareness of and
								devotion to the communities that read our content.
							</Paragraph>
						</section>
						<section>
							<H2>Advertising and Sponsorship Policy</H2>
							<Paragraph>
								BookPhysio.com's mission is to be your most trusted ally in your pursuit of health and
								well-being. We're committed to bringing you authoritative, approachable, and actionable
								content that inspires and guides you toward the best possible health outcomes for you
								and your family.
							</Paragraph>
							<Paragraph>
								The content and experiences produced by BookPhysio.com are created under strict
								editorial and medical guidelines to ensure accuracy, currency, and clarity.
								BookPhysio.com is funded through the sale of advertising that appears on its properties,
								which include websites, apps, social media, and partner publications. Be assured that
								we’re inflexible about our editorial autonomy. Besides input about broad topic areas, we
								do not allow sponsors to influence BookPhysio.com’s editorial content. In some cases,
								BookPhysio.com may offer co-created or branded content in partnership with our sponsors
								and/or present content created by our sponsors.
							</Paragraph>
							<Paragraph>
								This content is always clearly labelled and is detailed in sections 5 and 6 below.
							</Paragraph>
							<Paragraph>
								If you’d like to advertise with BookPhysio.com, contact us here. These are the specific
								guidelines we follow about the types of advertising we accept:
							</Paragraph>
							<Heading>1. We review the ads.</Heading>
							<Paragraph>
								We maintain sole discretion for determining the types of ads that will be accepted and
								displayed on BookPhysio.com properties. We reserve the right to reject, cancel, or
								remove any ads at any time and for any reason. We will provide prompt notice to the
								advertiser upon rejection, cancellation, or removal of any ad, along with an
								explanation. We also reserve the right to determine the appropriate placement of
								advertisements on BookPhysio.com properties.
							</Paragraph>
							<Heading>2. We don’t endorse any advertised products or services.</Heading>
							<Paragraph>
								Although products may appear on our site in the form of advertisements, under no
								circumstances shall our acceptance of any ad be considered an endorsement of the
								product(s) and/or service(s) advertised or for the company that manufactures,
								distributes, or promotes these product(s) or service(s). In some cases, BookPhysio.com’s
								editorial team may rate or rank certain products or services. These ratings or rankings
								are determined solely by the editorial team(s) without regard to any financial or
								advertising relationship that may exist with companies that provide the product or
								services.
							</Paragraph>
							<Heading>3. We will not accept certain types of ads.</Heading>
							<Paragraph>
								We will not accept any ad that is factually inaccurate and/or, in our opinion, in poor
								taste. Advertised products must be in market and readily available. BookPhysio.com will
								not permit, at any time, the placement of any ad for harmful, illegal, or objectionable
								products or services. Ads must not contain fraudulent, deceptive, or offensive material,
								including material that misrepresents, ridicules, or attacks an individual or group on
								the basis of age, colour, national origin, race, religion, sex, sexual orientation, or
								disability. Ads should not contain defamatory, libellous, slanderous, or threatening
								information.
							</Paragraph>
							<Paragraph>
								Ads must not be related to alcohol, firearms, ammunition, fireworks, gambling,
								pornography, tobacco, political campaigns, or the simulation of news or an emergency.
								All ads must clearly identify the advertiser. Any ad that could be misconstrued as
								editorial content will be clearly labelled as an advertisement.
							</Paragraph>
							<Heading>4. We clearly distinguish ads from editorial content.</Heading>
							<Paragraph>
								We maintain a distinct separation between advertisements and editorial content in every
								instance. All ads on BookPhysio.com properties are clearly and unambiguously identified
								as such. Clicking on an ad will take the user to an advertiser’s site or a sponsor’s
								resource centre hosted on a BookPhysio.com property. Sponsored resource centres are
								labelled as such, with the name of the sponsoring entity displayed.
							</Paragraph>
							<Heading>5. We clearly distinguish between sponsored and non­-sponsored content.</Heading>
							<Paragraph>
								BookPhysio.com seeks sponsorships from trusted and credible research organisations,
								manufacturers, and other service providers. Sponsorships provide financial benefit to
								BookPhysio.com but also benefit our users by providing current information on health
								services, medications, and treatments. Editorial content sponsored by an advertiser is
								subject to our editorial policy and is reviewed by our editorial staff and Medical
								Affairs team.
							</Paragraph>
							<Paragraph>
								When content (sponsored content, co-created content, or branded content) is provided by
								or influenced by a named sponsor, we take meaningful steps to ensure our users will not
								confuse sponsored content with BookPhysio.com’s original editorial content. Native
								advertising refers to links or ads within a piece of content that link to other content.
								BookPhysio.com may present advertising messages, advertisements, or sponsored posts in
								its social media channels.
							</Paragraph>
							<Heading>6. Affiliate program (e-commerce links in BookPhysio.com content)</Heading>
							<Paragraph>
								Our health and wellness editors will include links to relevant products they feel could
								be useful to our readers as part of the article creation process. While all products are
								chosen independently, we want you to know that BookPhysio.com may receive a payment if
								you purchase at the retailer’s site within 24 hours of clicking on one of the links we
								provide.
							</Paragraph>
							<Heading>7. We clearly distinguish content that is part of a Partner program.</Heading>
							<Paragraph>
								Occasionally BookPhysio.com will work with products, services, or organisations that
								align with our mission. BookPhysio.com vets these products, services, or organisations
								for quality and only presents what it feels is valuable to and in the best interest of
								our users. BookPhysio.com does not medically review the content and is not responsible
								for providing the products or services described on these pages.
							</Paragraph>
							<Paragraph>
								Any partner content of this type will be labelled with language that indicates it did
								not go through our standard editorial process and follows these Ad and Sponsorship
								Policy guidelines. In the instances where BookPhysio.com receives a payment when users
								purchase or sign up for the product or service, the page may also include this language:
								“BookPhysio.com and our partners may receive a portion of revenues if you make a
								purchase using a link above.” Partner pages may also include a “what’s this” link that
								takes users to a page that explains the nature of the partnership.
							</Paragraph>
							<H2>Content Disclaimer</H2>
							<Paragraph>
								BookPhysio.com is a digital publisher and does not offer personal health or medical
								advice. If you're facing a medical emergency, call your local emergency services
								immediately or visit the nearest emergency room or hospital. Before starting any
								nutrition, diet, exercise, fitness, medical, or wellness program, you should consult
								your healthcare provider.
							</Paragraph>
							<Paragraph>
								This content, developed through collaboration with licensed medical professionals and
								external contributors, including text, graphics, images, and other material contained on
								the website, apps, newsletter, and products ("Content"), is general in nature and for
								informational purposes only and does not constitute medical advice.
							</Paragraph>
							<ParagraphBold>
								This Content is not intended to be a substitute for professional medical advice,
								diagnosis, or treatment.
							</ParagraphBold>
							<Paragraph>
								Always consult your doctor or other qualified healthcare provider with any questions you
								may have regarding a medical condition, procedure, or treatment, whether it is a
								prescription medication, over-the-counter drug, vitamin, supplement, or herbal
								alternative. BookPhysio.com makes no guarantees about the efficacy or safety of products
								or treatments described on BookPhysio.com's Content.
							</Paragraph>
							<Paragraph>
								Health conditions and drug information contained are subject to change and are not
								intended to cover all possible uses, directions, precautions, warnings, drug
								interactions, allergic reactions, or adverse effects. BookPhysio.com does not recommend
								or endorse any specific test, clinician, clinical care provider, product, procedure,
								opinion, service, or other information that may be mentioned in BookPhysio.com's
								websites and Content.
							</Paragraph>
							<Paragraph>
								The disclaimers herein are provided on this page for ease of reference. This disclaimer
								supplements and is a part of BookPhysio.com's{' '}
								<Link to='/terms-and-conditions'>{`Terms & Conditions`}</Link>.
							</Paragraph>
							<DevideLine />
							<Paragraph>
								If you feel you may have a medical emergency, please call your local emergency services
								hotline immediately.
							</Paragraph>
							<Paragraph>
								<div>Australia: 000</div>
								<div>USA: 911</div>
								<div>Europe: 112</div>
							</Paragraph>
							<Paragraph>
								<Span>{`Last updated ${appendText(dateNum)} ${month}, ${year}`}</Span>
							</Paragraph>
						</section>
					</ContentArticle820>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};

const TopPadding = styled.div`
	width: 100%;
	height: 50px;

	@media (max-width: 767px) {
		display: none;
	}
`;
const Hovered = styled(Paragraph)`
	& a {
		color: ${({ theme }) => theme.palette.purp};
		transition: all 0.3s ease;
		cursor: pointer;
		&:hover {
			color: ${({ theme }) => theme.palette.main};
			text-decoration: none;
		}
	}
`;

const HoveredInline = styled(Hovered)`
	display: inline-block;
	margin: 0 5px;
	&:first-child {
		margin: 0 5px;
	}
`;
const SpanBold = styled.span`
	display: inline-block;
	font-weight: 600;
	font-size: 17px;
	color: #333e48;
	line-height: 1.5;
	margin: 0 5px;
`;

const SpanBoldInline = styled(SpanBold)`
	display: inline;
	margin: 0 5px 0 0;
`;

const Line = styled.div`
	position: relative;
	width: 100%;
	height: 3px;
	background-color: #424bec;
	margin-bottom: 40px;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 80px;
		width: 0;
		height: 0;
		border-left: 30px solid transparent;
		border-right: 30px solid transparent;
		border-top: 25px solid #424bec;
	}
	&:after {
		content: '';
		position: absolute;
		top: -4px;
		left: 80px;
		width: 0;
		height: 0;
		border-left: 30px solid transparent;
		border-right: 30px solid transparent;
		border-top: 25px solid #ffffff;
		z-index: 1;
	}
`;

const ImgsBlock = styled.div`
	display: flex;
	padding-left: 20px;
	padding-bottom: 20px;
	& > div:first-child {
		position: relative;
		z-index: 1;
	}
	& > div:last-child {
		position: relative;
		left: -20px;
	}
`;

const FoundersImage = styled.div`
	& img {
		border: 2px solid #ffffff;
		border-radius: 50%;
	}
`;

const ImgBlock = styled.div`
	& img {
		border-radius: 3px;
	}
`;
const TextBlock = styled.div``;

const Span = styled.span`
	display: block;
	font-weight: 600;
	margin-top: 20px;

	& a {
		color: #333e48;
		transition: all 0.3s ease;
		margin: 0;
		cursor: pointer;
		&:hover {
			color: ${({ theme }) => theme.palette.purp};
			text-decoration: none;
		}
	}
`;
const SpanItalic = styled(Span)`
	font-style: italic;
`;
const SpanGrey = styled(Span)`
	font-size: 16px;
	font-weight: 300;
	color: #7c7c7c;
	margin-top: 0px;
`;
const ListItems = styled.div`
	padding-left: 40px;

	@media (max-width: 767px) {
		padding-left: 10px;
	}
`;
const Item = styled.div`
	display: flex;
`;
const DotItem = styled.div`
	padding-left: 15px;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		top: 8px;
		left: 0;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background-color: #000000;
	}
`;
const Num = styled.span`
	flex: 0 0 20px;
	width: 20px;
	font-weight: 600;
`;
const DevideLine = styled.div`
	width: 100%;
	height: 1px;
	background-color: #000000;
	margin-top: 40px;
	margin-bottom: 40px;
`;
export default EditorialPolicy;
